import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Lime Render`}</h1>
    <p>{`Traditional natural hydraulic lime render or lime render as it is more commonly referred, has been used for hundreds of years across a broad range of substrates.`}</p>
    <p>{`Lime render is by far the most breathable render product available, although relatively soft, lime render expands and contracts with the building which makes it particularly suited to older buildings or wooden framed buildings.`}</p>
    <p>{`Chopped hair or fibre can be added to reinforce render base coats and the addition of casein which is a natural extract of cow’s milk is particularly useful on difficult substrates as it acts as a plasticiser. A new breed of natural hydraulic lime renders are available called monocouche (one coat) systems. These are particularly suited to light weight thermalite blocks, concrete blocks and stainless steel mesh. There are also wood fibre insulation boards and insulated render coats available making lime a favourable option for an eco-friendly new build project.`}</p>
    <p>{`Lime renders should not be confused with hydrated lime products. Hydrated lime products can be found in builders merchants and are used as an additive to cement mixes to extend the properties of the cement but differ vastly from natural hydraulic lime render.`}</p>
    <p>{`Natural hydraulic lime render, requires a high level of skill to apply. Good mixing practices are paramount to the quality of the product. It cannot be stressed enough how important it is to use well graded, washed sharp sand or river sand in lime renders, these are usually only found from specialist high quality suppliers and are far superior to manmade sand which are manufactured by crushing stone such as sand stone. Using low quality sand, contaminated sand or soft sand results in high levels of shrinkage, cracking and spalling of the render system ultimately causing failure of the render.`}</p>
    <p>{`Lime renders were traditionally applied without the use of corner beads or stop beads. Corner beads and stop beads may sometimes be used in restoration projects to rebuild the substrate but can be hidden underneath the finishing coat. Lime render is often applied in a rustic style following the contours of the building and allowing shading of the finish coat to give a traditional and natural appearance.`}</p>
    <p>{`Lime renders are available as standard renders or through coloured renders in many different colours and shades. The addition of harling or spar can mixed into the finishing coat and either cast by hand the traditional way or spray applied using gravity fed hoppers. Once cured, lime renders require very little maintenance and have a very long life span.`}</p>
    <p>{`Lime renders are eco-friendly as they absorb CO2 out of the atmosphere while curing. When fully cured the lime render sets back to a lime stone like state and provides a very durable finish.`}</p>
    <p>{`Suitable substrates: Solid stone with rubble filled walls, timber framed buildings, straw bale constructed buildings, new builds using thermalite blocks or breeze block, clay brick built buildings, Accrington engineered brick buildings, insulated fibreboard, metal lath or wooden lath. Other substrates may also be suitable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      